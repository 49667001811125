<template>
  <div class="d-flex justify-space-between mt-7 task-mobile-status-buttons " :class="classSticky">
    <v-btn
      class="primary--text task-mobile-status-buttons__btn task-mobile-status-buttons__btn--left"
      :class="[sticky ? 'task-mobile-status-buttons__btn--left-sticky' : 'task-mobile-status-buttons__btn--left']"
      color="secondary"
      large
      elevation="0"
      @click="cancelTask(taskId)"
    >
      <v-icon left>mdi-cancel</v-icon>
      {{ $t('task.btn_table_cancel') }}
    </v-btn>
    <v-btn
      v-if="showAcceptButton"
      class="white--text task-mobile-status-buttons__btn"
      :class="[sticky ? 'task-mobile-status-buttons__btn--right-sticky' : 'task-mobile-status-buttons__btn--right']"
      color="blue20"
      large
      elevation="0"
      @click="changeStatusToInProgress(taskId)"
    >
      <v-icon left>mdi-check</v-icon>
      {{ $t('task.btn_table_accept') }}
    </v-btn>
    <v-btn
      v-else
      class="white--text task-mobile-status-buttons__btn"
      :class="[sticky ? 'task-mobile-status-buttons__btn--right-sticky' : 'task-mobile-status-buttons__btn--right']"
      color="blue20"
      large
      elevation="0"
      @click="changeStatusToCompleted(taskId)"
    >
      <v-icon left>mdi-check-all</v-icon>
      {{ $t('task.btn_table_completed') }}
    </v-btn>
  </div>
</template>

<script>
import * as taskStatuses from '@/constants/taskStatuses';

export default {
  name: 'TaskMobileStatusButtons',

  props: {
    status: { type: String, default: '' },
    taskId: { type: Number, required: true },
    sticky: { type: Boolean, default: false },
  },
  computed: {
    showAcceptButton(status) {
      return status === taskStatuses.PENDING;
    },
    classSticky() {
      return {
        'task-mobile-status-buttons--sticky': this.sticky,
      };
    },
  },
  methods: {
    changeStatusToInProgress(id) {
      this.changeStatus(taskStatuses.IN_PROGRESS, id);
    },
    changeStatusToCompleted(id) {
      this.changeStatus(taskStatuses.COMPLETED, id);
    },
    cancelTask(id) {
      this.changeStatus(taskStatuses.CANCELED, id);
    },
    changeStatus(newStatus, id) {
      this.$emit('change-status', { newStatus, id });
    },
  },
};
</script>

<style lang="scss">
.task-mobile-status-buttons {
  margin-bottom: -16px;
  margin-left: -8px;
  margin-right: -8px;

  &__btn {
    width: 50%;
    border-radius: 0;
    &--right {
      border-bottom-right-radius: 4px;
    }
    &--left {
      border-bottom-left-radius: 4px;
    }
    &--right-sticky {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }
    &--left-sticky {
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }
  }
  &--sticky {
    margin-left: 0;
    margin-right: 0;
    position: sticky;
    bottom: 60px;
    z-index: 10;
  }
}
</style>
