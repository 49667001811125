<template>
  <base-modal v-model="isOpen" content-class="base-dialog" :title="title">
    <p v-if="text" class="mb-8 base-dialog__text">{{ text }}</p>
    <v-row class="mt-8" no-gutters>
      <v-col class="pr-1 pr-md-2" :cols="6">
        <v-btn block large elevation="0" color="secondary" class="primary--text" @click="cancel">{{
          $t('modal_button.cancel')
        }}</v-btn>
      </v-col>

      <v-col class="pl-1 pl-md-2" :cols="6">
        <v-btn block large elevation="0" color="primary" @click="confirm">{{ $t('modal_button.yes') }}</v-btn>
      </v-col>
    </v-row>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/BaseModal.vue';

export default {
  name: 'BaseDialog',
  components: { BaseModal },

  props: {
    title: { type: String, required: true },
    text: { type: String, default: '' },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    open() {
      return new Promise((resolve, reject) => {
        if (this.$options.reject) {
          this.$options.reject();
          this.cancelPromise();
        }

        this.$options.resolve = resolve;
        this.$options.reject = reject;

        this.isOpen = true;
      });
    },

    confirm() {
      this.closeAndResolveValue(true);
    },

    cancel() {
      this.closeAndResolveValue(false);
    },
    closeAndResolveValue(value) {
      this.$options.resolve(value);
      this.cancelPromise();

      this.isOpen = false;
    },

    cancelPromise() {
      this.$options.resolve = null;
      this.$options.reject = null;
    },
  },
  resolve: null,
  reject: null,
};
</script>
<style lang="scss">
.base-dialog {
  &__text {
    font-weight: $--font-weight-normal;
    font-size: 16px;
    line-height: 24px;
    color: $--black-color-0;
    opacity: 0.8;
  }

  .v-card__title {
    word-break: normal;
  }
}
</style>
