<template>
  <base-modal
    v-model="modelWrapper"
    :disabled-close-button="loading"
    :persistent="loading"
    content-class="task-modal-change-status"
  >
    <div v-if="loading" class="task-modal-change-status__overlay">
      <v-progress-circular class="task-modal-change-status__loader" color="white" size="40" width="2" indeterminate />
    </div>
    <template v-slot:title>
      <span>{{ $t('tasks.change_status') }} </span>
      <span class="task-modal-change-status__next-status">{{ textTitle }}</span>
    </template>

    <h3 class="task-modal-change-status__main-title">
      {{ $t('task.add_file_and_description') }}
    </h3>

    <form-builder
      ref="formBuilder"
      v-model="infoStatus"
      remove-label-suffix
      class="tasks-filters"
      :schema="schema"
      :initial-data="infoStatus"
    >
    </form-builder>

    <v-row no-gutters>
      <v-col class="pr-1 pr-md-2" :cols="6">
        <v-btn block large elevation="0" color="secondary" class="primary--text" @click="closeModal">{{
          $t('modal_button.back')
        }}</v-btn>
      </v-col>

      <v-col class="pl-1 pl-md-2" :cols="6">
        <v-btn block large elevation="0" color="primary" :disabled="loading" @click="changeStatus">{{
          $t('modal_button.save')
        }}</v-btn>
      </v-col>
    </v-row>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/BaseModal.vue';
import FormBuilder from '@/components/schema/FormBuilder.vue';
import { schema, schemaForMobile } from '@/schemas/tasksChangeStatus.schema';
import issuesService from '@/services/issues';
import { createModelData } from '@/schemas/createModelData';
import * as taskStatuses from '@/constants/taskStatuses';

import { CHANGE_TASKS_STATUS } from '@/constants/analyticsActions';
import analyticsService from '@/services/analytics';

export default {
  name: 'TaskModalChangeStatus',
  components: { BaseModal, FormBuilder },
  model: {
    prop: 'isOpen',
    event: 'update:isOpen',
  },
  inject: ['media'],
  props: {
    isOpen: { type: Boolean, default: false },
    newStatus: { type: String, required: true },
    taskId: { type: Number, required: true },
  },
  data() {
    return {
      infoStatus: createModelData(schema),
      loading: false,
    };
  },

  computed: {
    modelWrapper: {
      get() {
        return this.isOpen;
      },

      set(value) {
        this.$emit('update:isOpen', value);
      },
    },

    textTitle() {
      return this.newStatus ? this.$t(`tasks.${this.newStatus}`) : '';
    },
    isCanceled() {
      return this.newStatus === taskStatuses.CANCELED;
    },

    schema() {
      if (this.media.isMobile) {
        return schemaForMobile;
      }
      return schema;
    },
  },
  watch: {
    isOpen(newValue) {
      if (!newValue) {
        this.infoStatus = createModelData(schema);
      }
    },
  },
  methods: {
    closeModal() {
      this.modelWrapper = false;
    },
    async changeStatus() {
      analyticsService.track(CHANGE_TASKS_STATUS);
      const infoStatus = {
        issueId: this.taskId,
        comment: this.infoStatus.description,
        newStatus: this.newStatus,
        isShowDetailsToClient: this.infoStatus.isInform,
        files: this.infoStatus.file.map(item => ({
          backgroundFileId: item.backgroundFileId,
        })),
      };
      this.loading = true;
      try {
        await issuesService.changeIssueStatus(infoStatus);
        this.closeModal();
        this.$emit('success-change');
      } catch (e) {
        this.closeModal();
        this.$emit('fail-change');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.task-modal-change-status {
  &__overlay {
    position: absolute;
    background: rgba($--black-color-0, 0.3);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
  &__main-title {
    font-weight: $--font-weight-normal;
    font-size: 16px;
    line-height: 24px;
    color: #1e1e22;

    opacity: 0.8;
    margin-bottom: 40px;
    margin-top: -20px;
  }

  &__next-status {
    color: $--blue-color-20;
  }
  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
